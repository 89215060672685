<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="viewPurch" backdrop width="80rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="اضافة سيارة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{bobtitle}}</span>
                        </div>
                        <div @click="hide" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="px-3 py-2" style="direction:rtl">
                        <!-- <div :style="`direction:`+lang.dir" class="m-2">
                            <v-col cols="12">
                                <b-button v-b-toggle.returnInvoice>{{lang.invoice_return}}</b-button> 
                                <b-button v-b-toggle.addPayments>{{lang.make_payment}}</b-button> 
                            </v-col>
                        </div> -->
                        <div class="container">
                            <div><h6 class="backBlack text-center p-2">{{view_title}}</h6></div>
                            <b-table-simple :style="`direction:`+lang.dir">
                                <thead>
                                    <tr>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.invoiceno}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.bill_number }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.invoice_date}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ String(billifno.bill_date).substring(0,10) }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.vendor}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.vendor }}</td>
                                    </tr>
                                    <tr>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%"> {{lang.vendor_vatids}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.vat_number }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.vendor_mobile}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.mobile }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.bill_type}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.invtype }}</td>
                                    </tr>
                                    <tr>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%"> {{lang.payment_method}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.paytype }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.vendor_address}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.vendor_address }}</td>
                                        <th style="background:#000 !important;color:#fff !important;border:1px solid #555;border-bottom:2px solid #fff !important;text-align:center;width:8.33%">{{lang.invoice_notes}}</th>
                                        <td style="border:1px solid #555;text-align:center;width:8.33%">{{ billifno.inv_notes }}</td>
                                    </tr>
                                </thead>
                            </b-table-simple>

                        </div>
                        <div class="container">
                            <div><h6>{{lang.invoice_details}}</h6></div>
                            <v-simple-table striped hover style="width:100%;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th style="text-align:center;" class="backBlack">{{lang.item_code}}</th>
                                            <th style="text-align:center;" class="backBlack">{{lang.item_name}}</th>
                                            <th style="text-align:center;" class="backBlack">{{lang.qtty}}</th>
                                            <th style="text-align:center;" class="backBlack">{{lang.item_price}}</th>
                                            <th style="text-align:center;" class="backRed">{{lang.total}}</th>
                                            <th style="text-align:center;" class="backGreen">{{lang.vat}} 15%</th>
                                            <th style="text-align:center;" class="backBlack">{{lang.ftotal}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in billifno.rows" :key="index">
                                            <td style="text-align:center;">{{item.item_number}}</td>
                                            <td style="text-align:center;">{{item.item_name}}</td>
                                            <td style="text-align:center;">{{item.qty}}</td>
                                            <td style="text-align:center;">{{item.item_price}}</td>
                                            <td style="text-align:center;">{{item.total}}</td>
                                            <td style="text-align:center;">{{item.vat}}</td>
                                            <td style="text-align:center;">{{item.ftotal}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colspan="5" class="backEmpty"></th>
                                            <th :style="`text-align:`+lang.lalgin" class="backRed">{{lang.prices_exclusive_vat}}</th>
                                            <th :style="`background:lightgreen !important;text-align:`+lang.algin">{{totals}}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" class="backEmpty"></th>
                                            <th :style="`text-align:`+lang.lalgin" class="backBlack">{{lang.discount}}</th>
                                            <th :style="`text-align:`+lang.algin">{{billifno.discount}}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" class="backEmpty"></th>
                                            <th :style="`text-align:`+lang.lalgin" class="backGreen">{{lang.vat}} 15%</th>
                                            <th :style="`background:lightgreen !important;text-align:`+lang.algin">{{vats}}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" class="backEmpty"></th>
                                            <th :style="`text-align:`+lang.lalgin" class="backBlack">{{lang.prices_include_vat}}</th>
                                            <th :style="`text-align:`+lang.algin">{{ftotals}}</th>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                            <div style="display:none;"><h6>{{lang.payments}}</h6></div>
                            <v-simple-table striped hover style="width:100%;display:none;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th style="text-align:center;">{{lang.date}}</th>
                                            <th style="text-align:center;">{{lang.amounttotal}}</th>
                                            <th style="text-align:center;">{{lang.payment_method}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in payrows" :key="index">
                                            <td>{{item.receipt_date}}</td>
                                            <td>{{item.ftotal}}</td>
                                            <td>{{item.paytype}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>


<script>
import axios from 'axios'
export default{
  components: {  },
    name: 'ViewPurchase',
    data() {
        return {
            totals: 0,
            vats: 0,
            ftotals: 0,
            billid: 0,
            billifno: {

            },
            payrows: [],

        }
    },
    created() {
        // this.getBill();
        this.getPays();
    },
    methods: {
        getPays(){
            const post = new FormData();
            post.append('type','getbillPayments');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',this.billid);
             axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((resp) => {
                const res = resp.data;
                // console.log(res);
                this.payrows = res.results.data;
                
            })
        },
        getBill(){
            const post = new FormData();
            post.append('type','getBillinfo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.billid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((resp) => {
                const res = resp.data;
                
                this.billifno = res.results.data;
                
            }).then(() => {
                if(this.billifno.invtype == 1) { this.billifno.invtype = this.lang.purchase_invoice;}
                else{this.billifno.invtype == this.lang.expenses}

                if(this.billifno.paytype == 1) { this.billifno.paytype = this.lang.cash_payment;}
                else{this.billifno.paytype == this.lang.bank_payment}
                this.billifno.bill_date = this.billifno.bill_date.toString().substr(0, 10);
                this.totals = 0;
                this.vats = 0;
                this.ftotals = 0;
                if(this.billifno.rows.length > 0){
                    for(let i=0;i<this.billifno.rows.length;i++){
                        this.totals = +this.totals + parseFloat(this.billifno.rows[i].total);
                        this.vats = +this.vats + parseFloat(this.billifno.rows[i].vat);
                        this.ftotals = +this.ftotals + parseFloat(this.billifno.rows[i].ftotal);
                    }
                }
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        bobtitle: function(){
            if(this.billifno.expeneses_type == 0){
                return this.lang.view_purchase_bill.substring(4)
            }
            return this.lang.expenses
        },
        view_title: function(){
            if(this.billifno.expeneses_type == 0){
                return this.lang.view_purchase_bill
            }
            return this.lang.view_expenses
        },
        items: function(){
            return {
                    text: this.lang.view_purchase_bill,
                    disabled: true,
                    to: '/view-purchase',
                }
        }
    },
}
</script>